$green: #24af4d;
$pink: #fbafc9;
$lightBlue: #17afbd;
$gray: #757474;
$darkBlue: #0a2463;
$purple: #372549;
$orange: #fccc05;
$offWhite: #faf9f6;
$textColor: #550202;
$darkColor: #550202;
$veryDarkColor: #333333;
$black: #262730;
$white: #FFFFFF;

$primaryColor: #2798cc;
$secondaryColor: #a9adad;
$successColor: #03a138;
$dangerColor: #eb1c24;
$infoColor: #00a8be;
$royalBlue: #180375;
$offWhite: #f0f0f0;
$lightGray: #c8d3d5;
$softGray: #ececec;
$darkGray: #808080;
$mediumGray: #A7B0B7;
$warningColor: #ecd44c;


$projectPrimaryBlue: $primaryColor;
$projectPrimaryBlueDark: #1478a7;
$projectSecondaryGray: $secondaryColor;
$projectSecondaryGrayDark: #808080;
$projectSuccessGreen: $successColor;
$projectSuccessGreenDark: #0a7a2f;
$projectInfoTeal: $infoColor;
$projectInfoTealDark: #088494;
$projectWarningYellow: $warningColor;
$projectWarningYellowDark: #fdca40;
$projectDangerRed: $dangerColor;
$projectDangerRedDark: #a82428;


$projectBrightBlue: #63d2ff;
$projectBrightBlueDark: #41a7cf;
$projectOrange: #f8b279;
$projectOrangeDark: #cf650e;

@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap');

body{font-family: 'Gabarito', cursive;}

.send-button {
  background-color: #FF4820 !important;
}
.send-button-disabled {
  background-color: gray !important;
}

.ThreeColHeader{
  margin: 40px 0px;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  color: $darkBlue;
  opacity: 0.8;
}
.ThreeColText{
  font-weight: 300;
  padding-top:15px;
  font-size: 20px;
  color: $darkBlue;
}

  .column-image {
    max-width: 100%;
    height: auto;
    object-fit: cover; /* This property preserves the aspect ratio */
  
}
form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;

  input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
      border: 2px solid rgba(0, 206, 158, 1);
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
      border: 2px solid rgba(0, 206, 158, 1);
    }
  }

  label {
    margin-top: 1rem;
  }

  input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: rgb(249, 105, 14);
    color: white;
    border: none;
  }
}

/* NavbarComponent.css */

/* Navbar background color */



/* Navbar text color */
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
  transition: color 0.3s !important; /* Add a transition for smooth color change */
}

/* Text color on hover */
.navbar-dark .navbar-nav .nav-link:hover {
  color: $darkBlue !important; /* New text color on hover (light blue) */
}

/* Active link styling (if needed) */
.navbar-dark .navbar-nav .nav-link.active {
  background-color: #005f8e;
  color: white;
}

.greenAnchor {
  color: $purple !important;
}
.greenText {
  color: $green !important;
}

.pr-3 {
  @media only screen and (min-width: 768px) {
    padding-right: 3rem !important;
  }
}
.pl-3 {
  @media only screen and (min-width: 768px) {
    padding-left: 3rem !important;
  }
}

.page-header {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  width: 100%;
  background-position: 50%;
}

.page-header .container {
  z-index: 1;
}

.page-header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 991.98px) {
  .page-headers-sections .container .row .col-lg-12.mx-auto > .mb-4.w-25 {
    width: 50% !important;
  }
}

@media (max-width: 575.98px) {
  .page-headers-sections .container .row .col-lg-12.mx-auto > .mb-4.w-25 {
    width: 100% !important;
  }
}

.card.card-horizontal .card-body .card-title {
  font-size: 1.5rem;
}

.card.card-horizontal .card-body .card-title a {
  color: #344767;
}

.card.card-profile .card-body {
  padding: 1.25rem 2rem;
}

.card.card-profile .card-avatar {
  margin: 0 auto;
}

.card.card-profile .card-avatar .img {
  margin-top: 32px;
  border-radius: 50%;
  width: 130px;
}

.card.card-profile p.lead {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.778;
}

.card.card-profile .table td {
  font-weight: 300;
  font-size: 1rem;
}

.card.card-profile .card-before:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  display: block;
  z-index: 0;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}

.card.card-profile .card-before.mask-primary:before {
  background: linear-gradient(to bottom, rgba(15, 15, 15, 0), #e91e63 100%);
}

.card.card-profile .card-before.mask-info:before {
  background: linear-gradient(to bottom, rgba(15, 15, 15, 0), #1a73e8 100%);
}

.card.card-profile .card-before.mask-warning:before {
  background: linear-gradient(to bottom, rgba(15, 15, 15, 0), #fb8c00 100%);
}

.card.card-profile .card-before.mask-danger:before {
  background: linear-gradient(to bottom, rgba(15, 15, 15, 0), #f44335 100%);
}

.card.card-profile .card-before.mask-success:before {
  background: linear-gradient(to bottom, rgba(15, 15, 15, 0), #4caf50 100%);
}

.card.card-pricing .card-body {
  padding: 2.25rem;
}
.shadow-blur {
  box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

.backgroundImageIndustry {
  background-image: url("https://img.freepik.com/free-vector/healthcare-medical-science-banner-with-text-space_1017-24285.jpg");
}

.card.card-pricing .table tr {
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white,
    rgba(255, 255, 255, 0)
  );
}

.card.card-pricing .table td .badge {
  padding: 5px;
  font-size: 6px;
  position: relative;
  top: -2px;
}

.card.card-pricing[data-feature="icon"] .icon {
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: -22px;
  left: 0;
  right: 0;
}

.card.card-pricing[data-feature="icon"] .icon i {
  color: #fff;
  padding: 18px;
}

.card.card-pricing .plans i {
  font-size: 1.25rem;
}

.card.card-pricing .plans .card-category {
  text-transform: uppercase;
}

.card.card-pricing .icon-lg i {
  font-size: 1.75rem;
}

.text-bold {
  font-size: 1.1em !important;
}

.bordered-div {
  background-color: $offWhite !important;
  border: 10px solid #968f8f;
}
.bordered-div2 {
  border: 10px solid #6fb154;
}

a {
  position: relative;
  display: inline-block;
  color: rgb(128, 128, 128) !important;
  font-size: 1.5em;
  font-weight: 750;
  text-decoration: none !important;
  overflow: hidden;
  &::before {
    position: absolute;
    content: attr(data-content);
    top: 0;
    left: 0;
    width: 0;
    // height: 0;
    color: #6fb154;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    transition: width 275ms ease;
    // transition: height 275ms ease;
  }
  &:hover::before {
    width: 100%;
    // height: 100%;
  }
}

.desktopContainer {
  min-height: 100vh;
  padding-left: 45px;
  &Public {
    padding-left: 0px;
  }
  &Pinned {
    padding-left: 250px;
  }
}

.container .subText {
  @media only screen and (max-width: 768px) {
    position: absolute;
    width: 15em;
    top: 25%;
    font-size: 1.8em;
    color: rgb(107, 107, 107);
    font-weight: 600;
    left: 62%;
    text-align: left;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 5px;
  }
  @media only screen and (min-width: 769px) {
    position: absolute;
    width: 15em;
    top: 42%;
    font-size: 1.8em;
    color: rgb(107, 107, 107);
    font-weight: 600;
    left: 76%;
    text-align: left;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 5px;
  }
}

// .surveyBackground {
//   background-image: url("../assets/images/SightSourceSquare.gif");
//   background-size: cover;
//   z-index: 0;
// }



.bg-gradient-primary {
  background-image: linear-gradient(
    185deg,
    #639c4a 0%,
    #6fb154 100%
  ) !important;
}
.opacity-8 {
  opacity: 0.8 !important;
}


.container .btn {
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 70%;
  }
  width: 30em;
  position: absolute;
  top: 31%;
  left: 73%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.bannerBackground2 {
  background-image: url("https://images.unsplash.com/photo-1596367407372-96cb88503db6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
  height: 600px !important;
}

.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1) both;
}
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 70% 70%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 50% 10%;
    transform-origin: 50% 10%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-200) scaleY(1) scaleX(1);
    transform: translateX(-100) scaleY(1) scaleX(1);
    -webkit-transform-origin: 30% 10%;
    transform-origin: 30% 10%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.slide-in-blurred-left-delay {
  -webkit-animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1)
    1.5s both;
  animation: slide-in-blurred-left 2s cubic-bezier(0.23, 1, 0.32, 1) 1.5s both;
}
@-webkit-keyframes slide-in-blurred-left-delay {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left-delay {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 50% 10%;
    transform-origin: 50% 10%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 30% 10%;
    transform-origin: 30% 10%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.bg-pan-top {
  -webkit-animation: bg-pan-top 4s forwards;
  animation: bg-pan-top 4s forwards;
}

@-webkit-keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
    background: #fff;
  }
  90% {
    background-position: 50% 100%;
    background: #fff;
  }

  100% {
    background-position: 50% 0;
    background: #6fb154;
  }
}
@keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
    background: #fff;
  }
  90% {
    background-position: 50% 100%;
    background: #fff;
  }
  100% {
    background-position: 50% 0;
    background: #6fb154;
  }
}

.color-change-2x {
  -webkit-animation: color-change-2x 2s linear 1 alternate 3s both;
  animation: color-change-2x 2s linear 1 3s alternate both;
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #fff;
  }
  100% {
    background: #6fb154;
  }
}
@keyframes color-change-2x {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(167, 167, 167);
  }
}

@-webkit-keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}
@-moz-keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}
@-o-keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}

.button-wrap-2 {
  .circle {
    /* Inner circle */
    width: 30px;
    height: 30px; /* 20px smaller b/c of margin below */
    margin: 5px;
    background-color: #fff;
    border-radius: 60px;

    /* Overlays this circle on the .circle */
    z-index: 2;
    position: absolute;

    /* centers the text: adjust to desired size */
    padding: 22px 10px;
    text-align: center;

    /* shadow */
    -webkit-box-shadow: 0px 0px 7px 0px #6fb154;
    -moz-box-shadow: 0px 0px 7px 0px #6fb154;
    -o-box-shadow: 0px 0px 7px 0px #6fb154;
    box-shadow: 0px 0px 7px 0px #6fb154;
  }

  .outer-circle {
    /** outer circle **/
    width: 40px;
    height: 40px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 7px 0px #6fb154;
    -moz-box-shadow: 0px 0px 7px 0px #6fb154;
    -o-box-shadow: 0px 0px 7px 0px #6fb154;
    box-shadow: 0px 0px 7px 0px #6fb154;

    /*** outer circle position: under */
    z-index: 1;
    position: absolute;
  }

  .outer-circle:hover,
  .circle:active + .outer-circle {
    -webkit-animation: 1s ease-in 0s 1 both;
    -mox-animation: zoom 1s ease-in 0s 1 both;
    -o-animation: zoom 1s ease-in 0s 1 both;
    animation: zoom 1s ease-in 0s 1 both;
  }
}

.arrow {
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 82%;
    left: 87%;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    cursor: pointer;
  }
  @media only screen and (min-width: 769px) {
    position: relative;
    top: -2vh;
    left: 32vw;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    cursor: pointer;
  }
}
.explore {
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 82%;
    left: 9%;
  }
  @media only screen and (min-width: 769px) {
    position: absolute;
    top: 80%;
    left: 72%;
  }

  cursor: pointer;
}

.backgroundTexture {
  min-height: 100%;
  background: linear-gradient(
    0deg,
    rgba(111, 177, 84, 0.8),
    rgba(111, 177, 84, 0.8)
  );
  // url("../assets/images/WaveBackground.jpg");
  // background-size: cover;
}

.explore:hover {
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 82%;
    left: 9%;
  }
  @media only screen and (min-width: 769px) {
    position: absolute;
    top: 80%;
    left: 72%;
  }
}

.arrow span {
  @media only screen and (min-width: 769px) {
    display: block;
    width: 1vw;
    height: 1vw;
    border-bottom: 3px solid rgba(36, 180, 67, 1);
    border-right: 3px solid green;
    transform: rotate(45deg);
    margin: -15px;
  }
  @media only screen and (max-width: 768px) {
    display: block;
    width: 3vw;
    height: 3vw;
    border-bottom: 3px solid rgba(36, 180, 67, 1);
    border-right: 3px solid green;
    transform: rotate(45deg);
    margin-top: -10px;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-right: -10px;
  }
}
.explore:hover + .arrow span {
  position: relative;
  top: 80%;
  left: 22%;
  cursor: pointer;
  border-bottom: 3px solid rgba(36, 180, 67, 1);
  border-right: 3px solid green;
  transform: rotate(45deg);
  // margin-top: -10px;
  // margin-left: 20px;
  // margin-bottom: 10px;
  // margin-right: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.3s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.3s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.fade-in {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 3.8s both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 3.8s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.paralax {
  box-sizing: border-box;
  background: #222;
  padding: 0;
  margin: 0;
  font-family: "Sintony", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
}

.header {
  background-color: #fff;
  padding: 0;
  margin: 0;
}

.section {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  letter-spacing: 4px;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
  .fixed {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
  }
  .white {
    color: #fff;
  }
}

@for $i from 1 through 10 {
  .section:nth-child(#{$i}) {
    @if ($i==1) {
      background-color: #fff;
      color: #000;
      top: 0;
    } @else if($i==10) {
      background-color: #000;
      color: #fff;
      top: (100vh * ($i - 1));
    } @else {
      box-shadow: inset 0 1px 80px rgba(0, 0, 0, 0.14);
      background-color: hsl(200deg * $i, 90%, 40%);
      color: #fff;
      top: (100vh * ($i - 1));
    }
    z-index: ($i);
    .fixed {
      transform: translate(-50%, -50%);
    }
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.parallax {
  z-index: 1;
  background-color: aquamarine;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text1 {
  z-index: 1;
  position: absolute;
  font-size: 5em;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.text2 {
  position: absolute;
  font-size: 5em;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.parallax img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.material-icons {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.sec {
  z-index: 2;
  position: relative;
  background: #003329;
  padding: 100px;
}

.sec h2 {
  font-size: 3em;
  color: #fff;
  margin-bottom: 10px;
}

.sec p {
  font-size: 1em;
  color: #fff;
  font-weight: 300;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 768px) {
    width: 80% !important;
  }
}

//FIRE
$black: #111217;
$brownl: #70392f;
$brownd: #612e25;
$yellow: #ffdc01;
$orange: #fdac01;
$red: #f73b01;
$animationtime: 1.5s;

body {
  .fire {
    z-index: 0;
    position: absolute;
    left: 6%;
    top: 5%;
    transform: translate(-50%, -25%);
    height: 7vw;
    width: 7vw;
    //background-color:$red;
    .flames {
      z-index: 0;
      position: absolute;
      bottom: 40%;
      left: 50%;
      width: 30%;
      height: 30%;
      //background-color:$red;
      transform: translateX(-50%) rotate(45deg);
      .flame {
        z-index: 0;
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 0%;
        height: 0%;
        background-color: $yellow;
        border-radius: 1vw;
        &:nth-child(2n + 1) {
          animation: flameodd $animationtime ease-in infinite;
        }
        &:nth-child(2n) {
          animation: flameeven $animationtime ease-in infinite;
        }
        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: $animationtime/4;
        }
        &:nth-child(3) {
          animation-delay: $animationtime/4 * 2;
        }
        &:nth-child(4) {
          animation-delay: $animationtime/4 * 3;
        }
      }
    }
  }
}

@keyframes flameodd {
  0%,
  100% {
    width: 0%;
    height: 0%;
  }
  25% {
    width: 100%;
    height: 100%;
  }
  0% {
    background-color: $yellow;
    z-index: 1000000;
  }
  40% {
    background-color: $orange;
    z-index: 1000000;
  }
  100% {
    background-color: $red;
    z-index: -10;
  }
  0% {
    right: 0%;
    bottom: 0%;
  }
  25% {
    right: 1%;
    bottom: 2%;
  }
  100% {
    right: 150%;
    bottom: 170%;
  }
}

@keyframes flameeven {
  0%,
  100% {
    width: 0%;
    height: 0%;
  }
  25% {
    width: 100%;
    height: 100%;
  }
  0% {
    background-color: $yellow;
    z-index: 1000000;
  }
  40% {
    background-color: $orange;
    z-index: 1000000;
  }
  100% {
    background-color: $red;
    z-index: -10;
  }
  0% {
    right: 0%;
    bottom: 0%;
  }
  25% {
    right: 2%;
    bottom: 1%;
  }
  100% {
    right: 170%;
    bottom: 150%;
  }
}

.useUsCard {
  @media only screen and (max-width: 768px) {
    border-radius: 40px !important;

    padding-left: 40px;
    padding-right: 40px;
    margin: 20px;
  }
  @media only screen and (min-width: 768px) {
    border-radius: 70px !important;
    border-width: 10px !important;
    border-color: $green !important;
    padding-left: 100px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    padding-right: 100px;

    margin: 20px;
  }
}

.useUsCardText {
  @media only screen and (max-width: 768px) {
    font-size: 1.1em;
    font-weight: 450;
    margin: 20px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 1.3em;
    font-weight: 450;
  }
}

.useUsCardHeader {
  @media only screen and (max-width: 768px) {
    font-size: 1.7em;
    color: $darkBlue;
    margin: 0;
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
  }
  font-size: 2em;
  color: $darkBlue !important;
  margin: 0;
  text-align: left;
}

.postionHeader {
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
  }
  text-align: left;
}

//FlexCards
$optionDefaultColours: #ed5565, #fc6e51, #ffce54, #2ecc71, #5d9cec, #ac92ec;

.flexCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;

  font-family: "Roboto", sans-serif;
  transition: 0.25s;
  .dark {
    background: #232223;
    color: white;
  }
  .credit {
    position: absolute;
    bottom: 20px;
    left: 20px;

    color: inherit;
  }
  .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;

    min-width: 600px;
    max-width: 900px;
    width: calc(100% - 100px);

    height: 400px;

    @for $i from 1 through 4 {
      @media screen and (max-width: 798px - $i*80) {
        min-width: 600px - $i * 80;
        .option:nth-child(#{6-$i}) {
          display: none;
        }
      }
    }

    .option {
      position: relative;
      overflow: hidden;

      min-width: 60px;
      margin: 10px;
      //border:0px solid --defaultColor;

      background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
      background-size: auto 120%;
      background-position: center;

      cursor: pointer;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

      @for $i from 1 through length($optionDefaultColours) {
        &:nth-child(#{$i}) {
          --defaultBackground: #{nth($optionDefaultColours, $i)};
        }
      }
      &.active {
        flex-grow: 10000;
        transform: scale(1);

        max-width: 600px;
        margin: 0px;
        border-radius: 40px;

        background-size: auto 100%;
        .shadow {
          box-shadow: inset 0 -120px 120px -120px black,
            inset 0 -120px 120px -100px black;
        }
        .label {
          bottom: 20px;
          left: 20px;
          .info > div {
            left: 0px;
            opacity: 1;
          }
        }
        /*&:active {
               transform:scale(0.9);
            }*/
      }
      &:not(.active) {
        flex-grow: 1;

        border-radius: 30px;
        .shadow {
          bottom: -40px;
          box-shadow: inset 0 -120px 0px -120px black,
            inset 0 -120px 0px -100px black;
        }
        .label {
          bottom: 10px;
          left: 10px;
          .info > div {
            left: 20px;
            opacity: 0;
          }
        }
      }
      .shadow {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;

        height: 120px;

        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      }
      .label {
        display: flex;
        position: absolute;
        right: 0px;

        height: 40px;
        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          min-width: 40px;
          max-width: 40px;
          height: 40px;
          border-radius: 100%;

          background-color: white;
          color: var(--defaultBackground);
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;

          margin-left: 10px;

          color: white;

          white-space: pre;
          > div {
            position: relative;

            transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95),
              opacity 0.5s ease-out;
          }
          .main {
            font-weight: bold;
            font-size: 1.2rem;
          }
          .sub {
            transition-delay: 0.1s;
          }
        }
      }
    }
  }
}

.roundedProfileShadow {
  border-radius: "20px" !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}
.mt-n3 {
  margin-top: -1.5rem !important;
}

.rotating-card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.rotating-card-container .card-rotate {
  background: transparent;
  box-shadow: none;
}

.rotating-card-container .card-rotate:after {
  display: none;
}

.rotating-card-container .card {
  -webkit-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -moz-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -o-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -ms-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

.rotating-card-container .card .back,
.rotating-card-container .card .front {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  background-color: #fff;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
  justify-content: center;
  align-content: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.rotating-card-container .card .back .card-body,
.rotating-card-container .card .front .card-body {
  justify-content: center;
  align-content: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.rotating-card-container .card .back:after,
.rotating-card-container .card .front:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  border-radius: 0.5rem;
  background-image: linear-gradient(195deg, #ec407a, #d81b60);
  opacity: 0.85;
}

.rotating-card-container .card .front {
  z-index: 2;
  position: relative;
}

.rotating-card-container .card .back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 5;
  text-align: center;
  width: 100%;
  height: 100%;
}

.rotating-card-container .card .back.back-background .card-body {
  position: relative;
  z-index: 2;
}

.rotating-card-container .card .back .card-footer .btn {
  margin: 0;
}

.rotating-card-container .card .back .card-body {
  padding-left: 15px;
  padding-right: 15px;
}

.rotating-card-container:not(.manual-flip):hover .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rotating-card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-profile .rotating-card-container .front {
  text-align: left;
}

.input-group.input-group-dynamic .form-control,
.input-group.input-group-static .form-control {
  background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border-radius: 0px;
  transition: 0.2s ease;
}

.input-group.input-group-dynamic .form-control:not(:first-child),
.input-group.input-group-static .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}

.input-group.input-group-dynamic .form-control:not(:last-child),
.input-group.input-group-static .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}

.input-group.input-group-dynamic .form-control + .input-group-text,
.input-group.input-group-static .form-control + .input-group-text {
  border-left: 0;
}

.input-group.input-group-dynamic .form-control,
.input-group.input-group-dynamic .form-control:focus,
.input-group.input-group-static .form-control,
.input-group.input-group-static .form-control:focus {
  background-image: linear-gradient(0deg, #639c4a 2px, rgba(156, 39, 176, 0) 0),
    linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic .form-control:focus,
.input-group.input-group-static .form-control:focus {
  background-size: 100% 100%, 100% 100%;
  border-radius: 0;
}

.input-group.input-group-dynamic .input-group-text,
.input-group.input-group-static .input-group-text {
  border-right: 0;
  border-radius: 0;
}

.input-group.input-group-dynamic.is-focused .form-label,
.input-group.input-group-dynamic.is-filled .form-label,
.input-group.input-group-static.is-focused .form-label,
.input-group.input-group-static.is-filled .form-label {
  font-size: 0.6875rem !important;
  border-radius: 0;
}

.input-group.input-group-dynamic.is-focused .form-label,
.input-group.input-group-static.is-focused .form-label {
  top: -0.7rem;
  border-radius: 0;
}

.input-group.input-group-dynamic.is-focused label,
.input-group.input-group-static.is-focused label {
  color: #639c4a;
}

.input-group.input-group-dynamic.is-focused.is-valid label,
.input-group.input-group-static.is-focused.is-valid label {
  color: #4caf50;
}

.input-group.input-group-dynamic.is-focused.is-valid .form-control,
.input-group.input-group-dynamic.is-focused.is-valid .form-control:focus,
.input-group.input-group-static.is-focused.is-valid .form-control,
.input-group.input-group-static.is-focused.is-valid .form-control:focus {
  background-image: linear-gradient(0deg, #4caf50 2px, rgba(156, 39, 176, 0) 0),
    linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-focused.is-invalid label,
.input-group.input-group-static.is-focused.is-invalid label {
  color: #f44335;
}

.input-group.input-group-dynamic.is-focused.is-invalid .form-control,
.input-group.input-group-dynamic.is-focused.is-invalid .form-control:focus,
.input-group.input-group-static.is-focused.is-invalid .form-control,
.input-group.input-group-static.is-focused.is-invalid .form-control:focus {
  background-image: linear-gradient(0deg, #f44335 2px, rgba(156, 39, 176, 0) 0),
    linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-valid .form-control,
.input-group.input-group-dynamic.is-valid .form-control:focus,
.input-group.input-group-static.is-valid .form-control,
.input-group.input-group-static.is-valid .form-control:focus {
  background-image: linear-gradient(0deg, #4caf50 2px, rgba(156, 39, 176, 0) 0),
    linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-invalid .form-control,
.input-group.input-group-dynamic.is-invalid .form-control:focus,
.input-group.input-group-static.is-invalid .form-control,
.input-group.input-group-static.is-invalid .form-control:focus {
  background-image: linear-gradient(0deg, #f44335 2px, rgba(156, 39, 176, 0) 0),
    linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-filled.is-focused .form-label,
.input-group.input-group-dynamic.is-filled .form-label,
.input-group.input-group-static.is-filled.is-focused .form-label,
.input-group.input-group-static.is-filled .form-label {
  top: -1rem;
}

.input-group.input-group-outline.is-focused .form-label + .form-control,
.input-group.input-group-outline.is-filled .form-label + .form-control {
  border-color: #e91e63 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63;
}

.input-group.input-group-outline.is-focused .form-label,
.input-group.input-group-outline.is-filled .form-label {
  width: 100%;
  height: 100%;
  font-size: 0.6875rem !important;
  color: #e91e63;
  display: flex;
  line-height: 1.25 !important;
}

.input-group.input-group-outline.is-valid.is-focused
  .form-label
  + .form-control,
.input-group.input-group-outline.is-valid.is-filled
  .form-label
  + .form-control {
  border-color: #4caf50 !important;
  box-shadow: inset 1px 0 #4caf50, inset -1px 0 #4caf50, inset 0 -1px #4caf50;
  border-top-color: transparent !important;
}

.input-group.input-group-outline.is-valid.is-focused .form-label,
.input-group.input-group-outline.is-valid.is-filled .form-label {
  color: #4caf50;
}

.input-group.input-group-outline.is-valid.is-focused .form-label:before,
.input-group.input-group-outline.is-valid.is-focused .form-label:after,
.input-group.input-group-outline.is-valid.is-filled .form-label:before,
.input-group.input-group-outline.is-valid.is-filled .form-label:after {
  border-top-color: #4caf50;
  box-shadow: inset 0 1px #4caf50;
}

.input-group.input-group-outline.is-invalid .form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}

.input-group.input-group-outline.is-invalid.is-focused
  .form-label
  + .form-control,
.input-group.input-group-outline.is-invalid.is-filled
  .form-label
  + .form-control {
  border-color: #f44335 !important;
  box-shadow: inset 1px 0 #f44335, inset -1px 0 #f44335, inset 0 -1px #f44335;
  border-top-color: transparent !important;
}

.input-group.input-group-outline.is-invalid.is-focused .form-label,
.input-group.input-group-outline.is-invalid.is-filled .form-label {
  color: #f44335;
}

.input-group.input-group-outline.is-invalid.is-focused .form-label:before,
.input-group.input-group-outline.is-invalid.is-focused .form-label:after,
.input-group.input-group-outline.is-invalid.is-filled .form-label:before,
.input-group.input-group-outline.is-invalid.is-filled .form-label:after {
  border-top-color: #f44335;
  box-shadow: inset 0 1px #f44335;
}

.input-group.input-group-outline.input-group-sm .form-label,
.input-group.input-group-outline.input-group-sm label,
.input-group.input-group-dynamic.input-group-sm .form-label,
.input-group.input-group-dynamic.input-group-sm label,
.input-group.input-group-static.input-group-sm .form-label,
.input-group.input-group-static.input-group-sm label {
  font-size: 0.75rem;
}

.input-group.input-group-outline.input-group-lg .form-label,
.input-group.input-group-outline.input-group-lg label,
.input-group.input-group-dynamic.input-group-lg .form-label,
.input-group.input-group-dynamic.input-group-lg label,
.input-group.input-group-static.input-group-lg .form-label,
.input-group.input-group-static.input-group-lg label {
  font-size: 0.975rem;
}

.input-group.input-group-static .form-control {
  width: 100%;
}

.input-group.input-group-static label {
  margin-left: 0;
  margin-bottom: 0;
}

.bg-cover {
  background-size: cover;
}
.mask {
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.header-rounded-images .shape-1 {
  width: 55%;
  left: 15%;
}

.header-rounded-images .shape-2 {
  width: 35%;
  left: 20%;
}

.header-rounded-images .shape-3 {
  width: 50%;
  margin-top: -28rem;
}

.header-rounded-images .img-1 {
  right: 7%;
  width: 36%;
  margin-top: 12%;
}

.header-rounded-images .img-2 {
  left: 24%;
  width: 27%;
  margin-top: 10%;
}

.position-absolute {
  position: absolute !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.border-radius-xs {
  border-radius: 0.1rem;
}

.border-radius-sm {
  border-radius: 0.125rem;
}

.border-radius-md {
  border-radius: 0.375rem;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.border-radius-xl {
  border-radius: 0.75rem;
}

.border-radius-2xl {
  border-radius: 1rem;
}

.border-radius-section {
  border-radius: 10rem;
}

.border-bottom-end-radius-0 {
  border-bottom-right-radius: 0;
}

.border-top-end-radius-0 {
  border-top-right-radius: 0;
}

.border-bottom-start-radius-0 {
  border-bottom-left-radius: 0;
}

.border-top-start-radius-0 {
  border-top-left-radius: 0;
}
.min-vh-90 {
  min-height: 90vh !important;
}

.lightbox {
  /* Set the z-index to be higher than other elements on the page */
  z-index: 9999;
  /* Center the lightbox horizontally */
  left: 50%;
  transform: translateX(-50%);
  /* Add some padding to the top and bottom of the lightbox to make it look better */
  padding-top: 20px;
  padding-bottom: 20px;
}

.shadow-md {
  box-shadow: 0 0.2125rem 0.625rem 0 rgba(0, 0, 0, 0.1) !important;
}

.author {
  margin-top: -2rem !important;
}

.buttonHover {
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    background-color: #fff;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 200%;
    background-color: darken($green, 15%);
    transition: all 0.3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 200%;
      height: 300%;
    }
  }
}

.justify-content-start {
  justify-content: left !important;
}
.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}


.card-blog {
  overflow: hidden;

  &.card-plain {
    .card-body {
      padding: 15px 0;
    }
  }

  .card-image .img {
    width: 100%;
    height: 100%;
    align-content: center;
  }

  .pattern {
    margin-top: -45%;
  }

  &[data-background="pattern"] {
    .card-footer {
      border-top: none;
    }
  }
}



.pattern {
  width: 100%;
}

.stats{
  font-size: .8em;

    i{
      margin-right: 5px;
      position: relative;
      top: 2px;

    }

  &.stats-right {
    float: right;
    line-height: 30px;
    position: relative;
  }
}

.author {
  position: relative;
  display: inline-block;
  text-align: left;

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    margin-top:30px;
  }

  .meta {
    color: #a49e9e;
    font-size: .8em;
  }
}

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m--9 {
  margin: -10rem !important; }

.mt--9,
.my--9 {
  margin-top: -10rem !important; }

.mr--9,
.mx--9 {
  margin-right: -10rem !important; }

.mb--9,
.my--9 {
  margin-bottom: -10rem !important; }

.ml--9,
.mx--9 {
  margin-left: -10rem !important; }

.m--8 {
  margin: -8rem !important; }

.mt--8,
.my--8 {
  margin-top: -8rem !important; }

.mr--8,
.mx--8 {
  margin-right: -8rem !important; }

.mb--8,
.my--8 {
  margin-bottom: -8rem !important; }

.ml--8,
.mx--8 {
  margin-left: -8rem !important; }

.m--7 {
  margin: -6rem !important; }

.mt--7,
.my--7 {
  margin-top: -6rem !important; }

.mr--7,
.mx--7 {
  margin-right: -6rem !important; }

.mb--7,
.my--7 {
  margin-bottom: -6rem !important; }

.ml--7,
.mx--7 {
  margin-left: -6rem !important; }

.m--6 {
  margin: -4.5rem !important; }

.mt--6,
.my--6 {
  margin-top: -4.5rem !important; }

.mr--6,
.mx--6 {
  margin-right: -4.5rem !important; }

.mb--6,
.my--6 {
  margin-bottom: -4.5rem !important; }

.ml--6,
.mx--6 {
  margin-left: -4.5rem !important; }

.m--5 {
  margin: -3rem !important; }

.mt--5,
.my--5 {
  margin-top: -3rem !important; }

.mr--5,
.mx--5 {
  margin-right: -3rem !important; }

.mb--5,
.my--5 {
  margin-bottom: -3rem !important; }

.ml--5,
.mx--5 {
  margin-left: -3rem !important; }

.m--4 {
  margin: -1.5rem !important; }

.mt--4,
.my--4 {
  margin-top: -1.5rem !important; }

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important; }

.mb--4,
.my--4 {
  margin-bottom: -1.5rem !important; }

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important; }

.m--3 {
  margin: -1rem !important; }

.mt--3,
.my--3 {
  margin-top: -1rem !important; }

.mr--3,
.mx--3 {
  margin-right: -1rem !important; }

.mb--3,
.my--3 {
  margin-bottom: -1rem !important; }

.ml--3,
.mx--3 {
  margin-left: -1rem !important; }

.m--2 {
  margin: -0.5rem !important; }

.mt--2,
.my--2 {
  margin-top: -0.5rem !important; }

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important; }

.mb--2,
.my--2 {
  margin-bottom: -0.5rem !important; }

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important; }

.m--1 {
  margin: -0.25rem !important; }

.mt--1,
.my--1 {
  margin-top: -0.25rem !important; }

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important; }

.mb--1,
.my--1 {
  margin-bottom: -0.25rem !important; }

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 4.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.mt-7,
.my-7 {
  margin-top: 6rem !important; }

.mr-7,
.mx-7 {
  margin-right: 6rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 6rem !important; }

.ml-7,
.mx-7 {
  margin-left: 6rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.m-9 {
  margin: 10rem !important; }

.mt-9,
.my-9 {
  margin-top: 10rem !important; }

.mr-9,
.mx-9 {
  margin-right: 10rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 10rem !important; }

.ml-9,
.mx-9 {
  margin-left: 10rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p--9 {
  padding: -10rem !important; }

.pt--9,
.py--9 {
  padding-top: -10rem !important; }

.pr--9,
.px--9 {
  padding-right: -10rem !important; }

.pb--9,
.py--9 {
  padding-bottom: -10rem !important; }

.pl--9,
.px--9 {
  padding-left: -10rem !important; }

.p--8 {
  padding: -8rem !important; }

.pt--8,
.py--8 {
  padding-top: -8rem !important; }

.pr--8,
.px--8 {
  padding-right: -8rem !important; }

.pb--8,
.py--8 {
  padding-bottom: -8rem !important; }

.pl--8,
.px--8 {
  padding-left: -8rem !important; }

.p--7 {
  padding: -6rem !important; }

.pt--7,
.py--7 {
  padding-top: -6rem !important; }

.pr--7,
.px--7 {
  padding-right: -6rem !important; }

.pb--7,
.py--7 {
  padding-bottom: -6rem !important; }

.pl--7,
.px--7 {
  padding-left: -6rem !important; }

.p--6 {
  padding: -4.5rem !important; }

.pt--6,
.py--6 {
  padding-top: -4.5rem !important; }

.pr--6,
.px--6 {
  padding-right: -4.5rem !important; }

.pb--6,
.py--6 {
  padding-bottom: -4.5rem !important; }

.pl--6,
.px--6 {
  padding-left: -4.5rem !important; }

.p--5 {
  padding: -3rem !important; }

.pt--5,
.py--5 {
  padding-top: -3rem !important; }

.pr--5,
.px--5 {
  padding-right: -3rem !important; }

.pb--5,
.py--5 {
  padding-bottom: -3rem !important; }

.pl--5,
.px--5 {
  padding-left: -3rem !important; }

.p--4 {
  padding: -1.5rem !important; }

.pt--4,
.py--4 {
  padding-top: -1.5rem !important; }

.pr--4,
.px--4 {
  padding-right: -1.5rem !important; }

.pb--4,
.py--4 {
  padding-bottom: -1.5rem !important; }

.pl--4,
.px--4 {
  padding-left: -1.5rem !important; }

.p--3 {
  padding: -1rem !important; }

.pt--3,
.py--3 {
  padding-top: -1rem !important; }

.pr--3,
.px--3 {
  padding-right: -1rem !important; }

.pb--3,
.py--3 {
  padding-bottom: -1rem !important; }

.pl--3,
.px--3 {
  padding-left: -1rem !important; }

.p--2 {
  padding: -0.5rem !important; }

.pt--2,
.py--2 {
  padding-top: -0.5rem !important; }

.pr--2,
.px--2 {
  padding-right: -0.5rem !important; }

.pb--2,
.py--2 {
  padding-bottom: -0.5rem !important; }

.pl--2,
.px--2 {
  padding-left: -0.5rem !important; }

.p--1 {
  padding: -0.25rem !important; }

.pt--1,
.py--1 {
  padding-top: -0.25rem !important; }

.pr--1,
.px--1 {
  padding-right: -0.25rem !important; }

.pb--1,
.py--1 {
  padding-bottom: -0.25rem !important; }

.pl--1,
.px--1 {
  padding-left: -0.25rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 4.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 4.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.pt-7,
.py-7 {
  padding-top: 6rem !important; }

.pr-7,
.px-7 {
  padding-right: 6rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 6rem !important; }

.pl-7,
.px-7 {
  padding-left: 6rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.p-9 {
  padding: 10rem !important; }

.pt-9,
.py-9 {
  padding-top: 10rem !important; }

.pr-9,
.px-9 {
  padding-right: 10rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 10rem !important; }

.pl-9,
.px-9 {
  padding-left: 10rem !important; }

  

  