$lightBlue: #17afbd;

*, *::after, *::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap');

body{font-family: 'Gabarito', cursive;}
.banner{
    width: 100%;
    height: 100vh;
    background: 
    linear-gradient(to bottom,$lightBlue, rgb(10, 36, 99,0.6)), 
    url("https://images.unsplash.com/photo-1637929476734-bd7f5f78e40a?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
}
.banner2{
    width: 100%;
    height: 50vh;
    background: 
    linear-gradient(to bottom,$lightBlue, rgb(10, 36, 99,0.6)), 
    url("https://images.unsplash.com/photo-1637929476734-bd7f5f78e40a?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
}

.navbar{
    width: 85%;
    margin: auto;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    justify-content: space-between;
}

.logo{
    width: 100px;
    cursor: pointer;
}

.navbar ul li{
    list-style: none;
    display: inline-block;
    margin: 0 20px;
    position: relative;
}
.navbar ul{
  padding-top: 25px;
}

.navbar ul li a{
    text-decoration: none;
    color: #FFF !important;
    text-transform: uppercase;
    font-weight: 700;
}

.navbar ul li::after{
    content: '';
    height: 3px;
    width: 0;
    background: white;
    position: absolute;
    left: 0;
    bottom: -10px;
    transition: 0.4s;
}

.navbar ul li:hover::after{
    width: 100%;
}


.content{
  @media only screen and (max-width: 768px) {
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 25%;
    width: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: rgb(230, 230, 230);
  }
  @media only screen and (min-width: 769px) {
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 25%;
    width: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: rgb(230, 230, 230);
  }

    
}

.content2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 25%;
    right: 25%;
    width: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: rgb(230, 230, 230);
}

.content h1{
    font-size: 70px;
    margin-top: 60px;
    font-weight: 700;
    color: #e2e2e2;
    text-transform: uppercase;
}
.content2 h1{
    font-size: 70px;
    margin-top: 60px;
    font-weight: 700;
    color: #e2e2e2;
    text-transform: uppercase;
}

.content p{
    @media only screen and (min-width: 769px) {margin: 40px 0px;
        font-weight: 600;
        font-size: 40px;
        color: #e2e2e2;
        opacity: 0.9;}
    @media only screen and (max-width: 769px) {margin: 40px 0px;
    font-weight: 600;
    font-size: 30px;
    color: #e2e2e2;
    opacity: 0.9;}
    
}

button{
    width: 200px;
    padding: 15px 0;
    text-align: center;
    margin: 20px 10px;
    border-radius: 25px;
    font-weight: bold;
    border: 2px solid #0F0F0F;
    background: transparent;
    color: #0F0F0F;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: 0.9s;
    text-transform: uppercase;
}

span{
    background-color: #0F0F0F;
    height: 100%;
    width: 0;
    border-radius: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: 1s;
}
button:hover{
    color: white;
}
button:hover span{
    width: 100%;

}

.social{
    width: 50%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 75px;

    cursor: pointer;
    
}
.social i{
    color: #0F0F0F;
    transition: 0.5s;
    transform: scale(1);
}

.social i:hover{
    transform: scale(1.7);
    
    
}