body{font-family: 'Gabarito', cursive;}

.whiteNav p{
 color: #000 !important;
}

.jpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    color: #000;
}

.jpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.jpt3__navbar-links_logo{
    margin-right: 2rem;
}
.jpt3__navbar-links_logo img{
    width: 62.56px;
    height: 16.02;
}
.jpt3__navbar-links_container{
    display: flex;
    flex-direction: row;
    color: #fff !important;
}
.jpt3__navbar-links_container a{
    color: #fff !important;
    font-weight: 600;
}
.jpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.jpt3__navbar-links_container p,
.jpt3__navbar-sign p,
.jpt3__navbar-menu_container p
{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor:pointer
}

.jpt3__navbar-sign button,
.jpt3__navbar-menu_container button {
   padding:0.5rem 1rem;
   color: #fff;
   background: #FF4820;
   font-weight: 600;
   font-size: 18px;
   line-height: 25px;
   border-radius: 5px;
   border:none;
   outline: none;
   cursor: pointer;
}

.jpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.jpt3__navbar-menu svg {
    cursor: pointer;
}

.jpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    /* background: var(--color-footer); */
    padding: 2rem;
    position: absolute;
    top: 40px;
    right:0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}
.jpt3__navbar-menu_container a{
  color:"blue" !important
}

.jpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.jpt3__navbar-menu_container-links-sign{
    display: none;
}
.jpt3__navbar-menu_container-links a{
    color: #0a2463 !important;
}

@media screen and (max-width : 1050px) {
    .jpt3__navbar-links_container{
        display: none;
    }
    .jpt3__navbar-menu{
        display: flex;
    }
}
@media screen and (max-width : 700px) {
    .jpt3__navbar{
        padding: 2rem 4rem;
    }
}
@media screen and (max-width : 550px) {
    .jpt3__navbar{
        padding: 2rem ;
    }
    .jpt3__navbar-sign{
        display: none;
    }
    .jpt3__navbar-menu_container{
        top:20px
    }
    .jpt3__navbar-menu_container-links-sign{
        display: block;
    }
}